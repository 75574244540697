.react-datepicker-wrapper {
  width: 100%;
}
// .css-1fdsijx-ValueContainer{
//     height: 50px;
// }
// .css-qbdosj-Input{
//     padding-top: 32px !important;
// }

// .css-1dimb5e-singleValue{
//     padding-top: 13px;
// }

// .css-t3ipsp-control{
//     border-color: #E3E3E3 !important;
//     box-shadow: 0 0 0 1px #E3E3E3 !important;
//
// }
.react-datepicker-popper {
  z-index: 2 !important;
}

.css-1nmdiq5-menu {
  z-index: 3 !important;
}

// .css-13cymwt-control {
//     height: 50px !important;
// }

// .css-19bb58m{
//     padding-top: 22px !important;
// }

.filter-row {
  .css-13cymwt-control {
    height: 50px;
    .css-1jqq78o-placeholder {
      padding-top: 14px;
    }
    .css-1dimb5e-singleValue {
      padding-top: 14px;
    }
  }
  .css-t3ipsp-control {
    height: 50px;
    border-color: #e3e3e3 !important;
    box-shadow: 0 0 0 1px #e3e3e3 !important;
    .css-1jqq78o-placeholder {
      padding-top: 14px;
    }
    .css-1dimb5e-singleValue {
      padding-top: 14px;
    }
  }
}

//antd

.data-table-card {
  .ant-table-content {
    .ant-table-tbody {
      tr {
        &:nth-of-type(2n + 1) {
          background-color: transparent;
        }
      }

      td {
        padding: 0.5rem;
      }
    }
  }
}

.ant-tooltip {
  display: none;
}

.page-a-table.ant-table-wrapper {
  .ant-table-thead > tr > th {
    max-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      max-width: fit-content;
      background-color: #cccccc;
    }
  }
  .ant-table-thead {
    th.ant-table-column-has-sorters:hover {
      background-color: #cccccc;
    }
  }
  .ant-table {
    th {
      color: $__primarycolor !important ;
    }
  }
  .ant-table-tbody tr:hover {
    background: #cccccc !important;
  }

  .ant-table-tbody > tr > td:first-child {
    color: $__primarycolor; // Set different background color for the first td in the body
  }
}
.ant-table-wrapper {
  .ant-table-thead {
    th.ant-table-column-has-sorters {
      &:hover {
        background: $__whitecolor;
      }
    }

    th.ant-table-column-sort {
      background: $__whitecolor;
    }

    > tr {
      > th {
        background: $__whitecolor;

        &:not(:last-child) {
          &:not(.ant-table-selection-column) {
            &:not(.ant-table-row-expand-icon-cell) {
              &:not([colspan]) {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }

      > td {
        background: $__whitecolor;

        &:not(:last-child) {
          &:not(.ant-table-selection-column) {
            &:not(.ant-table-row-expand-icon-cell) {
              &:not([colspan]) {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  td.ant-table-column-sort {
    background: transparent;
  }

  .ant-table-column-sorter-up.active {
    color: $__basecolor;
  }

  .ant-table-column-sorter-down.active {
    color: $__basecolor;
  }

  .ant-table-tbody {
    .ant-table-row {
      > .ant-table-cell-row-hover {
        background: transparent;
      }
    }
  }

  .ant-table {
    font-family: $__body__font__family;
    font-size: $__body__font__size;
    color: $__secondarycolor;
  }
}

.ant-table-content {
  .ant-table-tbody {
    tr {
      &:nth-of-type(2n + 1) {
        background-color: $__dark__light;
      }
    }
  }

  table {
    th {
      white-space: nowrap;
      border-top: 1px solid #e2e5e8;
      padding: 1rem 0.75rem;
    }

    td {
      border-top: 1px solid #e2e5e8;
      white-space: nowrap;
      vertical-align: middle;
      padding: 0.5rem;
      color: $__gray;
    }

    .ant-table-tbody {
      .ant-table-rowant-table-row {
        color: $__gray;
      }
    }
  }
}

.ant-pagination {
  .ant-pagination-item-active {
    color: $__whitecolor !important;
    background-color: $__cantaloupe;
    border-color: $__cantaloupe;

    &:hover {
      border-color: $__cantaloupe;
    }
  }

  .ant-pagination-item {
    color: $__cantaloupe;
  }
}

.ant-table td h2 {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.ant-table td h2 a {
  color: #333333;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 10px 20px;
}

.dataTables_length label {
  align-items: baseline;
}

.alert-dismissible {
  .btn-close {
    left: 0;
    right: unset;
  }
}
hr.border-prim {
  border: 1px solid $__primarycolor;
  opacity: 1;
  width: 98%;
}

.funds-table {
  .ant-table-content table tr > td:first-child a,
  [data-layout-mode="dark"] .ant-table-content table tr > td:first-child a {
    color: #0d6efd !important;
    &:hover {
      color: $__primarycolor-hover !important;
    }
  }
}
