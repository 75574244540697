.table {
  padding: 0;
  background: $__white;
  .table > :not(:first-child) {
    border-top: 0;
  }
  th {
    white-space: $__nowrap;
    border-top: 1px solid $__sea__gray;
    @include margin-padding(null, 1rem 0.75rem);
  }
  td {
    border-top: 1px solid $__sea__gray;
    white-space: $__nowrap;
    vertical-align: $__middle;
    @include margin-padding(null, 0.5rem);
    a {
      color: $__gray;
    }
    .pro-avatar {
      height: 1.65rem;
      margin-right: 5px;
      width: $__auto;
      @include rounded(0.25rem);
    }
    h2 {
      display: $__inline__block;
      font-size: $__font__size__14;
      font-weight: $__regular;
      @include margin-padding(0, 0);
      vertical-align: $__middle;
      a {
        color: $__gray;
      }
    }
    a {
      color: $__gray;
    }
  }
  > :not(:first-child) {
    border-top: 0;
  }
}
.datatable.table-striped {
  border-width: 0px !important;
  border-top: 0px !important;
  tbody > tr > th {
    border-bottom: 0px;
    border-top: 0px;
  }
  tbody > tr > th.fs-5 {
    border-width: 0px !important;
  }
  table tbody tr {
    &:hover {
      background-color: $__primarycolor-hover !important;
    }
  }
}
.user-add-shedule-list {
  h2 {
    a {
      @include margin-padding(null, 10px);
      display: $__inline__block;
      span {
        color: $__dark__gray;
        display: $__block;
        font-size: $__font__size__12;
        margin-top: 3px;
      }
    }
  }
  a {
    border: 1px dashed $__light__battle__gary;
    color: $__light__battle__gary;
    border-radius: 5px;
    display: $__inline__block;
    padding: 10px;
  }
}
.table-bordered {
  td {
    border: 1px solid $__light__gary;
  }
  th {
    border: 1px solid $__light__gary;
  }
}
.filter-row {
  .select2-container {
    .select2-selection.select2-selection--single {
      height: 50px;
    }
  }
  .btn {
    min-height: 50px;
    text-transform: $__uppercase;
    @include margin-padding(null, 12px);
  }
}
.card-table {
  .card-header {
    border-bottom: 1px solid $__light__goose;
    padding: 15px;
  }
  .card-body {
    padding: 0;
  }
  .card-footer {
    text-align: $__center;
    a {
      color: $__gray;
    }
  }
}
.dropdown-action {
  .dropdown-toggle {
    &::after {
      border: 0;
    }
  }
}
.action-icon {
  color: $__dark__battle__gary;
  font-size: $__font__size__18;
  display: $__inline__block;
}
.table-inbox {
  td {
    padding: 0.5rem;
  }
  .starred {
    &.fa-star {
      color: $__yellow;
    }
  }
}
.unread {
  .name,
  .subject,
  .maildate {
    color: $__black;
    font-weight: $__semibold;
  }
}
.table-striped {
  tbody {
    tr {
      &:nth-of-type(2n + 1) {
        background-color: $__dark__light;
      }
    }
  }
}
.custom-table {
  tr.holiday-completed {
    color: $__light__battle__gary;
  }
  td {
    @include margin-padding(null, 10px 20px);
  }
  tr {
    td.td-sum {
      color: $__dark__blues !important;
      font-weight: bold;
    }
    td.td-green {
      color: green !important;
      font-weight: bold;
      font-size: large;
      border-top: 2px solid green;
      border-bottom: 3px double green;
    }
    td.td-red {
      color: red !important;
      font-weight: bold;
      border-bottom: 3px dashed red;
    }

    td.td-sum-borders {
      border-top: 2px solid $__dark__blues;
      position: relative;
      /* Ensure positioning context for pseudo-elements */
      border-bottom: 4px double $__dark__blues;
      /* Main border line */
    }
  }
}
.leave-table {
  .l-name {
    width: 200px;
  }
  .l-days {
    width: 140px;
  }
}
.review-section {
  @include margin-padding(0 0 30px, null);
  .review-header {
    background-color: $__white;
    border: 1px solid $__blue__angel;
    text-align: $__center;
    @include margin-padding(0, 15px);
    h3 {
      font-size: 1.4rem;
      margin-bottom: 3px;
    }
  }
}
.form-control-sm {
  &.form-control {
    height: 30px;
    appearance: $__auto;
    @include rounded(0);
  }
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper
  .ant-table-pagination-right {
  justify-content: flex-start;
}
