.board-view-header {
    @include margin-padding( 0 0 30px, null);
    .pro-teams {
        text-align: $__center;
        @include margin-padding( 0 20px 15px 0, null);
        @extend %inline-flex;
        @include respond-below(custom991) {
            display: $__none;
        }

        .avatar-title{
            background-color: $__cantaloupe;
        }
        .pro-team-lead {
            @extend %align-items-center;
            @extend %display-flex;
            h4 {
                font-size: $__font__size__16;
                @include margin-padding(0 0 0 16px, null);
                .avatar-group {
                    @extend %inline-flex;
                    .avatar {
                        margin-right: 0;
                        position: $__relative;
                        display: $__inline__block;
                        width: 34px;
                        height: 34px;
                        background-color: $__transparent;
                        .border {
                            border: 3px solid $__white !important;
                        }
                    }
                }
            }
        }
        .pro-team-members {
            margin-left: 15px;
            @extend %align-items-center;
            @extend %display-flex;
            h4 {
                margin-bottom: 0;
                margin-left: 16px;
                font-size: $__font__size__16;
            }
        }
        .avatar-group {
            .avatar {
                @include margin-padding(0 -0.75rem 0 0, null);
            }
        }
    }
    .pro-progress-bar {
        @extend %display-flex;
        @extend %align-items-center;
        .progress {
            width: $__full__width;
            height: 15px;
            background-color: $__metallic-silver;
            box-shadow: $__unset;
            @include margin-padding(0 10px 0 0, null);
        }
        h4 {
            margin-bottom: 0;
            margin-right: 10px;
            font-size: $__font__size__13;
            text-transform: $__uppercase;
        }
    }
}
.kanban-cont {
    overflow: $__auto;
    @extend %display-flex;
    @include margin-padding(null, 0 0 20px);
    .kanban-list{
        align-self: $__flex-start;
        min-width: 300px;
        width: 300px;
        @include margin-padding(0 30px 0 0, null);
        &.kanban-danger {
            background-color: $__light__red;
            .kanban-header {
                background-color: $__dark__red;
            }
            .progress-bar {
                background-color: $__dark__red;
            }
        }
        .kanban-wrap {
            @include margin-padding(null, 20px);
            .task-board-header {
                font-size: $__font__size__14;
                font-weight: $__medium;
                @include margin-padding(null, 15px 15px 5px);
                @extend %justify-content-between;
                @extend %display-flex;
                a {
                    color: $__plum__black;
                }
            }
            .task-board-body {
                font-size: $__font__size__13;
                @include margin-padding(null, 0 15px 15px);
                .kanban-info {
                    font-size: $__font__size__12;
                    @extend %align-items-center;
                    @extend %display-flex;
                    .progress {
                        width: $__full__width;
                        @include margin-padding(0 10px 0 0, null);
                    }
                }
                .kanban-footer {
                    font-size: $__font__size__12;
                    margin-top: 10px;
                    align-items: $__end;
                    @extend %justify-content-between;
                    @extend %display-flex;
                    .task-date {
                        display: $__block;
                    }
                    .task-avatar {
                        @include rounded(50%);
                        position: $__relative;
                    }
                    .task-user-count {
                        font-size: $__font__size__10;
                        font-weight: $__medium;
                        display: $__inline__block;
                        margin-left: 3px;
                        position: $__relative;
                        top: 1px;
                    }
                }
            }
            .card{
                background-color: $__white;
                border: 1px solid $__metallic-silver;
                @include rounded(2px);
                box-shadow: $__unset;
                margin: 0 0 12px;
            }
        }
        .add-new-task {
            text-align: $__center;
            @include margin-padding(null, 0 20px 20px);
            a{
                color:$__gray;
                
            }
        }
        .kanban-header {
            @include margin-padding(null, 10px 20px);
            @extend %justify-content-between;
            @extend %align-items-center;
            @extend %display-flex;
            .status-title {
                color: $__white;
                font-size: $__font__size__16;
                font-weight: $__medium;
            }
            .kanban-action{
               > a{
                    color: $__white;
                    background-color: rgba(0, 0, 0, 0.3);
                    width: 22px;
                    height: 22px;
                    @include rounded(50%);
                    @extend %justify-content-center;
                    @extend %align-items-center;
                    @extend %inline-flex;
                }
            }
        }
        &.kanban-info{
            background-color: #e7f3fe;
            .kanban-header {
                background-color: #42a5f5;
            }
            .progress-bar {
                background-color: #42a5f5;
            }
        }
        &.kanban-success {
            background-color: $__lightgreenstext;
            .kanban-header {
                background-color: $__lightgreens;
            }
            .progress-bar {
                background-color: $__lightgreens;
            }
        }
        &.kanban-warning {
            background-color: $__orangecolorslight;
            .kanban-header {
                background-color: $__orangecolors;
            }
            .progress-bar {
                background-color: $__orangecolors;
            }
        }
        &.kanban-purple  {
            background-color: $__purpletextlight;
            .kanban-header {
                background-color: $__purpletext;
            }
            .progress-bar {
                background-color: $__purpletext;
            }
        }
        &.kanban-primary  {
            background-color: $__cantaloupelight;
            .kanban-header {
                background-color: $__cantaloupe;
            }
            .progress-bar {
                background-color: $__cantaloupe;
            }
        }

    }
}
.chat-main-row {
    overflow: $__auto;
    padding-bottom: $__inherit;
    padding-top: $__inherit;
    @include position($__absolute,0,0,0,0);
    .chat-main-wrapper {
        display: $__table;
        height: $__full__height;
        table-layout: $__fixed;
         width: $__full__width;
        .message-view {
            display: $__table-cell;
            height: $__full__height;
            float: $__none;
            padding: 0;
            position: $__static;
            vertical-align: $__top;
            width: 75%;
            @include respond-below(custom991) {
                &.task-view{
                    width: 100%;
                }
            }
            @include respond-below(custom991) {
                &.chat-profile-view {
                    @include transition(all 0.4s ease);
                    z-index: 1041;
                    width: 300px;
                    display: $__table-cell;
                    @include margin-padding(60px -300px 0 0, 0 0 60px);
                    @include position($__fixed,0,0,null,null);
                    @include transform(translateX(0px));
                    &.opened {
                        margin-right: 0 ; 
                    }
                }
            }
        }
        .task-left-sidebar {
            width: 58.3333%;
            @include respond-below(custom991) {
                width: $__full__width;
               &.chat-sidebar{
                   display: $__none;
                  
               }
           }
        }
        .task-right-sidebar {
            width: 41.6667%;
            @include respond-below(custom991) {
                z-index: 1041;
                width: 300px;
                display: $__table-cell;
                @include transform(translateX(0px));
                @include margin-padding(60px -300px 0, 0 0  60px);
                @include position($__fixed,0,0,null,null);
                @include transition(all 0.4s ease);
                &.opened {
                    margin-right: 0;
                }
            }
        }
        .chat-window {
            display: $__table;
            height: $__full__height;
            table-layout: $__fixed;
             width: $__full__width;
            background-color: $__mix__gray;
            .fixed-header {
                background-color: $__white;
                border-bottom: 1px solid $__mild___gray;
                padding: 10px 15px;
                .user-details{
                    a {
                        color: $__dark__ash__gray;
                        text-transform: $__uppercase;
                    }
                    .typing-text {
                        color: $__primarycolor;
                        font-size: $__font__size__12;
                        text-transform: $__lowercase;
                    }
                    .last-seen {
                        color: $__dark__gray;
                        display: $__block;
                        font-size: $__font__size__12;
                    }
                }
                .navbar {
                    border: 0 none;
                    margin: 0;
                    min-height: $__auto;
                    padding: 0;
                    .profile-rightbar{
                        display: $__none;
                        color: $__metalic__gray;
                        font-size: $__font__size__26;
                        margin-left: 15px;
                        @include respond-below(custom991) {
                            display: $__block;
                        }
                    }
                }
                .custom-menu {
                    margin: 1px 0 0;
                    li{
                        a {
                            color: $__metalic__gray;
                            font-size: $__font__size__22;
                            line-height: 32px;
                            @include margin-padding( null , 0 15px 0 0);
                            
                        }
                        .dropdown-menu{
                            left:$__auto;
                            right:0;
                            @include margin-padding(0, 0 );
                            .dropdown-item{
                                font-size: $__font__size__14;
                                color: $__default__light;
                            }
                        }
                        .dropdown-menu-right{
                            a{
                                font-size: $__font__size__14;
                            }
                        }
                    }
                    .profile-rightbar {
                        display: $__none;
                        @include respond-below(custom991) {
                            display: $__block;
                        }
                    }
                }
            }
        }
    }
}
.task-wrapper {
    padding: 20px;
     width: $__full__width;
    margin: 0 auto;
    box-sizing: $__border__box;
    .task-list-body {
        max-height: 500px;
        overflow: $__auto;
        #task-list {
            @extend %ul_reset;
            border-bottom: 1px solid $__mild___gray;
            li {
                @include margin-padding(0, 0);
                &.completed {
                    .task-container {
                        background: $__platinum;
                        .complete-btn {
                            background: $__successgreen !important;
                            border: 1px solid $__successgreen !important;
                            color: $__white;
                        }
                    }
                }
                .task-container {
                    display: $__table;
                    background: $__white;
                    width: $__full__width;
                    border: 1px solid $__mild___gray;
                    border-bottom: $__none;
                    box-sizing: $__border-box;
                    position: $__relative;
                    @include margin-padding(null, 8px 15px);
                    @include transition(all 0.2s ease);
                    .task-action-btn {
                        display: $__table-cell;
                        vertical-align: $__middle;
                        text-align: $__left;
                        &.task-check {
                            text-align: $__right !important;
                            width: 40px;
                        }
                        .action-circle {
                            background-color: $__white;
                            border: 1px solid $__goose-gray;
                            height: 20px;
                            width: 20px;
                            display: $__inline__block;
                            text-align: $__center;
                            cursor: $__pointer;
                            @include rounded(100%);
                            @include transition(all 0.2s ease);
                        }
                        .action-circle.large {
                            height: 24px;
                            width: 24px;
                            .material-icons {
                                font-size: $__font__size__16;
                                vertical-align: -4px;
                            }
                        }
                    }
                    .task-label {
                        display: $__table-cell;
                        font-weight: $__regular;
                        vertical-align: $__middle;
                        color: $__gray;
                        word-break: $__break-all;
                    }
                    .task-btn-right{
                        display: $__none;
                        .action-circle.large {
                            height: 24px;
                            width: 24px;
                            .material-icons {
                                font-size: $__font__size__16;
                                vertical-align: -4px;
                                color: $__goose-gray;
                            }
                        }
                    }
                   &:hover{
                        .task-btn-right{
                            display: $__block;
                        }
                   }
                }
                &.completed{
                    .task-container {
                        .task-label{
                                color: #ccc;
                        }
                    }
                }
            }
        }
    }
    .task-list-footer {
        position: $__relative;
        .new-task-wrapper{
            @include transition(all 0.2s ease);
            overflow: $__hidden;
            visibility: $__hidden;
            .visible {
                max-height: 300px;
                overflow: $__auto;
            }
            textarea {
                box-sizing: $__border-box;
                width: $__full__width;
                border: 1px solid $__mild___gray;
                border-top: 0;
                height: 45px;
                resize: $__none;
                @include margin-padding(0 0 20px, 10px 45px);
                @include transition(all 0.2s ease);
            }
            .error-message {
                color: $__lava__red;
                font-style: $__italic;
            }
            .add-new-task-btn {
                background: $__white;
                border: 1px solid $__goose-gray;
            }
            .btn {
                float: $__right;
                cursor: $__pointer;
                @include margin-padding(null, 6px 12px);
            }
        }
    }
}
.chat-content-wrap {
    height: $__full__height;
    position: $__relative;
     width: $__full__width;
    .chat-wrap-inner {
        overflow: $__auto;
        @include position($__absolute,0,0,0,0);
        .chats {
            @include margin-padding(null, 15px 15px 30px);
        }
        .task-header {
            font-size: $__font__size__14;
            font-weight: $__medium;
            @include margin-padding(null, 15px 15px 5px);
            @extend %display-flex;
            .assignee-info {
                border: 1px solid $__transparent;
                flex: 0 1 180px;
                position: $__relative;
                @include rounded(10px);
                @include margin-padding(null, 5px 10px 5px 5px);
                @extend %align-items-center;
                @extend %inline-flex;
                &:hover {
                    border-color: $__metallic-silver;
                    .remove-icon {
                        visibility: $__visible;
                    }
                }
                a {
                    color: $__gray;
                    @extend %display-flex;
                    @extend %align-items-center;
                }
                .remove-icon {
                    background-color: $__metallic-silver;
                    @include rounded(50%);
                    color: $__gray;
                    cursor: $__pointer;
                    height: 20px;
                    margin-right: 10px;
                    transform: translateY(-50%);
                    visibility: $__hidden;
                    width: 20px;
                    @include position($__absolute,50%,null,null,0);
                    @extend %justify-content-center;
                    @extend %align-items-center;
                    @extend %display-flex;
                }
                .task-head-title {
                    color: $__candycolor;
                    font-size: $__font__size__12;
                }
                .task-assignee {
                    font-size: $__font__size__13;
                    max-width: 120px;
                    overflow: $__hidden;
                    text-overflow: $__ellipsis;
                    white-space: $__nowrap;
                }
            }
            .task-due-date {
                border: 1px solid $__transparent;
                flex: 0 0 160px;
                position: $__relative;
                @include margin-padding(0 0 0 10px, 5px 10px 5px 5px);
                @extend %align-items-center;
                @extend %display-flex;
                @include rounded(10px);
                &:hover {
                    border-color: $__metallic-silver;
                    .remove-icon {
                        visibility: $__visible;
                    }
                }
                .due-icon{
                    background-color: $__white;
                    border: 1px solid $__goose-gray;
                    @include rounded(50%);
                    color: $__goose-gray;
                    height: 30px;
                    line-height: 30px;
                    margin-left: 10px;
                    width: 30px;
                    @extend %justify-content-center;
                    @extend %display-flex;
                    @extend %align-items-center;
                    span{
                        i{
                            font-size: $__font__size__18;
                            @include position($__relative,4px,null,null,null);
                        }
                    }
                }
                a {
                    color: $__gray;
                    @extend %display-flex;
                    @extend %align-items-center;
                }
                .remove-icon {
                    background-color: $__metallic-silver;
                    @include rounded(50%);
                    color: $__gray;
                    cursor: $__pointer;
                    height: 20px;
                    visibility: $__hidden;
                    width: 20px;
                    @include transform(translateY(-50%));
                    @include position($__absolute,50%,null,null,0);
                    @include margin-padding(0 10px 0 0, null);
                    @extend %justify-content-center;
                    @extend %align-items-center;
                    @extend %display-flex;
                }
            }
        }
        .task-desc {
            align-items: $__flex-start;
            @include margin-padding(5px 0 15px, null);
            @extend %display-flex;
            .task-desc-icon {
                color: $__goose-gray;
                @include margin-padding(5px 0 15px, null);
            }
            .task-textarea {
                 width: $__full__width;
                @extend %display-flex;
            }
        }
        .task-information {
            color: $__light__wolf;
            font-size: $__font__size__11;
            line-height: 17px;
            min-width: 1px;
            margin-left: 50px;
            .task-info-line {
                overflow-wrap: $__normal;
                .task-user {
                    color: $__dark__gray;
                    font-weight: $__medium;
                }
            }   
            .task-time {
                display: $__inline__block;
                padding-left: 10px;
            }
        }
    }
}
.task-chat-contents {
    background-color: $__white;
    .chat.chat-left {
        @include margin-padding(0 0 20px, null);
    }
    .completed-task-msg {
        @include margin-padding(0 0 30px 50px, null);
        .task-success {
            color: $__successcolor;
            font-size: $__font__size__13;
        }
        .task-time {
            color: $__light__wolf;
            display: $__inline__block;
            font-size: $__font__size__11;
        }
    }
}
.chat-footer {
    background-color: $__white;
    border-top: 1px solid $__mild___gray;
    @include margin-padding(null, 15px);
    .call-duration {
        display: $__inline__block;
        font-size:$__font__size__30;
        margin-top: 4px;
        @include position($__absolute,null,0,null,null);
        @include respond-below(custom575) {
            display: $__block;
            margin-top: 0;
            margin-bottom: 10px;
            position: $__inherit;
        }
    }
    .task-followers {
        margin-top: 15px;
        @extend %display-flex;
        .followers-title {
            @include margin-padding(5px 10px 0 0, null);
            .avatar {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }
        }
        .followers-add {
            background-color: $__white;
            border: 1px solid $__goose-gray;
            @include rounded(50%);
            color: $__goose-gray;
            display: $__inline__block;
            font-size: $__font__size__20;
            height: 34px;
            line-height: 34px;
            text-align: $__center;
            width: 34px;
            i {
                line-height: 28px;
            }
        }
    }
    .message-bar {
        display: $__table;
        height: 44px;
        position: $__relative;
         width: $__full__width;
        .message-inner {
            display: $__table-row;
            height: $__full__height;
            width: $__full__width;
            @include margin-padding(null, 0 8px);
        }   
        .link {
            color: $__dark__battle__gary;
            display: $__table-cell;
            font-size: $__font__size__20;
            position: $__relative;
            vertical-align: $__middle;
            width: 30px;
        }
        .message-area {
            display: $__table-cell;
            .input-group {
                position: $__relative;
                flex-wrap: $__wrap;
                align-items: $__stretch;
                 width: $__full__width;
                @extend %display-flex;
                .form-control {
                    background-color: $__white;
                    border: 1px solid $__mild___gray;
                    box-shadow: $__none;
                    color: $__dark__ash__gray;
                    display: $__block;
                    font-size: $__font__size__14;
                    height: 44px;
                    margin: 0;
                    padding: 6px 12px;
                    resize: $__none;
                }
            }
            .btn {
                height: 44px;
                width: 50px;
            }
        }
    }
    .btn-custom{
        background: $__primarycolor;
        background: linear-gradient(to right, $__primarycolor 0%, $__light__chilli__pepper 100%);
        color: $__white;
    }
}
.task-chat-view {
    .chat-left {
        .chat-content {
            border: 0 !important;
            padding: 0 !important;
            p {
                font-size: $__font__size__13;
                margin-bottom: 0;
            }
        }
    }
    
}
.task-assign {
    float: $__left;
    a {
        float: $__left;
    }
    .task-complete-btn {
        background-color: $__white;
        border: 1px solid $__goose-gray;
        color: $__gray;
        font-size: $__font__size__14;
        @include margin-padding(null, 6px 10px);
        @include rounded(5px);
        @extend %align-items-center;
        @extend %display-flex;
    }
}
.status {
    border: 2px solid $__white;
    height: 10px;
    width: 10px;
    @include rounded(50px);
    @include position($__absolute,null,null,0,0);
    &.online {
        background-color: $__successcolor;
    }
}