.text-left{
    text-align: left;
}
/* .m-b-10 {
    margin-bottom: 10px;
} */

.payslip-custom-title span{
    border-bottom: 1px solid #FF9B44;
}
.payslip-custom-title {
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
}

.invoice-custom-details {
    float: right;
    text-align: right;
}
.inv-custom-logo {
    height: auto;
    margin-bottom: 0px;
    max-height: 80px;
    width: auto;
}
.lst {
    float: left;
    text-align: right;
}
.logo-title {
    float: left;
}
.table.t-inv {
    width: 40%;
    
 
}
.table.t-inv tr,.table.t-inv th{
    border-width: 0;
}
.table.t-inv.table-borderless, .table.t-inv.table-borderless th{
    border-style: none ;

}
[data-layout-mode=dark] body table.table.table-bordered td{
    border-top: 1px  #bbc4cc;
}
.t-green {
    border: 2px solid #55ce63 !important;
    border-collapse: collapse;
}
.tr-green {
    border-bottom: 2px solid #2e3439;
    border-left: 2px solid #55ce63;
    border-right: 2px solid #55ce63;
    border-collapse: collapse;

}
.t-red {
    border: 2px solid #f62d51 !important;
}
.tr-red {
    border-bottom: 2px solid #2e3439;
    border-left: 2px solid #f62d51;
    border-right: 2px solid #f62d51;
}
.float-left{
    float: left;
}
