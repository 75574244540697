[data-layout-mode="dark"] .css-13cymwt-control{
  background-color: #16191c !important;
color: #bbc4cc !important;
border: 1px solid #2e3840 !important;

}

[data-layout-mode="dark"] .css-t3ipsp-control:hover{
  background-color: #16191c !important;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
  
}
[data-layout-mode="dark"] .css-t3ipsp-control{
  background-color: #16191c !important;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
  
}

[data-layout-mode="dark"] .form-focus .form-control:focus{
  background-color: #16191c !important;
  color: #bbc4cc !important;
  border: 1px solid !important;
    border-color: $__metallic-silver !important;

  
}


[data-layout-mode="dark"] .header .user-menu.nav > li.flag-nav .dropdown-menu{
   background-color: #29363c !important;
  color: #bbc4cc !important;
}

 [data-layout-mode="dark"] .dropdown-menu {
   background-color: #29363c !important;
  color: #bbc4cc !important;
 }

 [data-layout-mode="dark"] .dropdown-item:hover {
   background-color: #29363c !important;
  color: #bbc4cc !important;
 }

 [data-layout-mode="dark"] .topnav-dropdown-header .notification-title{
  color: #bbc4cc !important;
 }

  [data-layout-mode="dark"] .dropdown-item{
    color: #bbc4cc !important;
  }

 [data-layout-mode="dark"] .notifications .notification-list li .list-item .list-body .message-content{
   color: #bbc4cc !important;
 }

[data-layout-mode="dark"] .notifications .notification-list li .list-item .list-body .message-author{
  color: #bbc4cc !important;
}

 [data-layout-mode="dark"] .notifications .notification-list li .noti-title{
  color: #bbc4cc !important;
 }

[data-layout-mode="dark"] .header .user-menu.nav > li.flag-nav .dropdown-menu .dropdown-item{
   background-color: #29363c !important;
  color: #bbc4cc !important;
}
[data-layout-mode="dark"] .header .user-menu.nav > li.flag-nav .dropdown-menu .dropdown-item:hover{
   background-color: #29363c !important;
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .topnav-dropdown-footer{
   background-color: #29363c !important;
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .topnav-dropdown-footer a{
  color: #bbc4cc !important;
}

[data-layout-mode="dark"] .anticon svg {
 color: #bbc4cc !important;
}

[data-layout-mode="dark"] .ant-table-wrapper .ant-table-thead > tr > td {
  background: #16191c;
}

[data-layout-mode="dark"] .css-1nmdiq5-menu *{
  background-color: #16191c !important;
  color: #bbc4cc !important;
}
[data-layout-mode=dark] .ant-table{
  border: 1px solid #2e3840;
    background: #16191c;
}


[data-layout-mode="dark"] .react-datepicker *{
  background-color: #16191c !important;
  color: #bbc4cc !important;
}


[data-layout-mode=dark] th.ant-table-cell.ant-table-column-has-sorters{
  background: #16191c;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode=dark] th.ant-table-cell{
  background: #16191c !important;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}
[data-layout-mode=dark] .page-a-table th.ant-table-cell{
  background: #16191c !important;
  color:  $__primarycolor !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode=dark] th.ant-table-cell.ant-table-column-has-sorters:hover{
  background: #16191c;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}
[data-layout-mode=dark] td.ant-table-cell{
  background: #16191c;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode=dark] .ant-table-content .ant-table-tbody tr:nth-of-type(2n+1){
  background: transparent !important;
}
[data-layout-mode=dark] .ant-table-content .ant-table-tbody td a{
  // background: #16191c;
  color: #bbc4cc !important;
  // border: 1px solid #2e3840 !important;
}
// [data-layout-mode=dark] td.ant-table-cell:hover{
//   background: #16191c;
//   color: #bbc4cc !important;
//   border: 1px solid #2e3840 !important;
// }


[data-layout-mode=dark] .ant-table-content .ant-table-tbody tr:nth-of-type(2n+2){
  background: transparent !important;
}


// [data-layout-mode=dark] .ant-table-wrapper .ant-table-tbody > tr > td:hover{
//   background: #16191c;
//   color: #bbc4cc !important;
//   border: 1px solid #2e3840 !important;
// }
[data-layout-mode=dark] .ant-table-wrapper .ant-table-tbody > tr > td{
  background: #16191c;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}
[data-layout-mode=dark] .page-a-table .ant-table-tbody  tr:hover{
  background: $__gray !important;
  color: #bbc4cc !important;
  border: 1px solid #bbc4cc !important;
}
[data-layout-mode=dark] .page-a-table  .ant-table-thead > tr > th:hover{
  background-color: $__gray !important;
}
[data-layout-mode=dark] .modal-content{
  background: #16191c;
  color: #bbc4cc !important;
  border: 1px solid #2e3840 !important;
}

[data-layout-mode=dark] .fc .fc-daygrid-day-number{
  color: #bbc4cc !important;
}

[data-layout-mode=dark] .css-1dimb5e-singleValue{
  color: #bbc4cc !important;
}

 [data-layout-mode=dark] .css-1jqq78o-placeholder{
  color: #bbc4cc !important;
 }

 [data-layout-mode=dark] .picker-input >input{
  color: #bbc4cc !important;
 }


 .anticon.anticon-caret-up.ant-table-column-sorter-up {
  right: 7px;
  position: relative;
  bottom: 4px;
}
.anticon.anticon-caret-up.ant-table-column-sorter-down {
  position: relative;
}
.ant-table-column-sorter-up::after {
  content: "↑";
}

.ant-table-column-sorter-down::after {
  content: "↓";
}
.anticon svg {
  display: none;
}

.ant-table-wrapper {
	.ant-table-column-sorter {
		position: relative;
		top: 5px;
    
	}
}


.scroll-rtl div::-webkit-scrollbar {
  display: none;
}

.scroll-rtl div::-webkit-scrollbar-thumb {
  display: none;
}

.scroll-rtl div::-webkit-scrollbar-track {
 display: none;
}

.scroll-rtl div{
    margin-right: 0px !important;
}


.container-fluid {
	.row {
		display: flex;
		justify-content: center;
	}
}

.me-2 {
	margin-left: 5px;
	position: relative;
	top: 2px;
}


.mini-sidebar{
  .two-col-bar{
    .sidebar{
      width: 60px;
        .sidebar-right{
          display: none ;
        }
    }

  }
 &.expand-menu{
    .sidebar{
      width: 230px;
        .sidebar-right{
          display: block ;
        }
    }
 }

}

[data-layout=horizontal] body .sidebar .sidebar-menu ul ul {
    display: block !important;
}