.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: $__infocolor-hover;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; // Position above the element
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
      content: "";
      position: absolute;
      top: 100%; // Bottom of the tooltip
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $__infocolor-hover transparent transparent transparent;
    }
  }

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
}
.spinner-custom {
  margin-top: 25rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
