html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.modal-content {
   width: auto;
   border-radius: 15px !important;
}
button {
  display: block;
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  user-select: none;
  cursor: pointer;
  outline: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button:active {
  box-shadow: inset 0px 0px 80px 0px rgba(0, 0, 0, 0.25);
}

#wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#appp {
  width: 100%;
  height: 600px;
  position: relative;
  padding: 0 !important;
}


.calculator.massb {
  width: 100% !important;
  height: 100% !important;
  min-height: 550px;
  background: black;
  display: flex !important;
  flex-direction: column !important;
  /* border-radius: 15px; */
}

#wrapper .calculator {
  box-shadow: 0px 0px 20px 0px #aaa;
  border-radius: 15px;
}

.calculator-display {
  color: white;
  background: #1c191c;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  line-height: 130px;
  font-size: 6em;
  flex: 1;
}

.auto-scaling-text {
  display: inline-block;
  /* background-color: aqua; */
}

.calculator-display .auto-scaling-text {
  padding: 0 30px;
  position: absolute;
  right: 0;
  transform-origin: right;
}

.calculator-keypad {
  height: 400px;
  display: flex;
}

.calculator .input-keys {
  width: 240px;
}

.calculator .function-keys {
  display: flex;
}

.calculator .digit-keys {
  background: #e0e0e7;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  border-bottom-left-radius: 15px;
}

.calculator-key {
  width: 80px;
  height: 80px;
  border-top: 1px solid #777;
  border-right: 1px solid #666;
  text-align: center;
  line-height: 80px;
}
.calculator .function-keys .calculator-key {
  font-size: 2em;
}
.calculator .function-keys .key-multiply {
  line-height: 50px;
}
.calculator .digit-keys .calculator-key {
  font-size: 2.25em;
}
.calculator .digit-keys .key-0 {
  width: 160px;
  text-align: left;
  padding-left: 32px;
}
.calculator .digit-keys .key-dot {
  padding-top: 1em;
  font-size: 0.75em;
}
.calculator .operator-keys .calculator-key {
  color: white;
  border-right: 0;
  font-size: 3em;
}

.calculator .function-keys {
  background: linear-gradient(
    to bottom,
    rgba(202, 202, 204, 1) 0%,
    rgba(196, 194, 204, 1) 100%
  );
}
.calculator .operator-keys {
  background: linear-gradient(
    to bottom,
    rgba(252, 156, 23, 1) 0%,
    rgba(247, 126, 27, 1) 100%
  );
  /* border-bottom-right-radius: 15px; */
}
/* .calculator .operator-keys :last-child{
  
  border-bottom-right-radius: 50px!important;
} */
