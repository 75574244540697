@media print {
  .header.hide-on-print,
  .hide-on-print {
    display: none;
  }

  .page-wrapper {
    padding: 0 !important;
  }

  .print-no-margins {
    margin: 0;
    padding: 0;
  }

  #print-absolute {
    margin: 0;
    padding: 10px;
    width: 100%;
    height: 100vh;
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    box-shadow: none;
    border: none;
  }

  #print-landscape {
    width: 907px;
    height: 1409px;
    /* scale: 0.7; */
    margin: 0;
  }

  #print-landscape .card {
    scale: 0.8;
    margin-top: -50px;
  }
}
