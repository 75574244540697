.fc-button {
    background: $__light__vampire;
    border: $__none;
    color: $__granite__gary;
    text-transform: $__capitalize;
    box-shadow: $__none ;
    height: $__auto ;
    @include rounded(3px);
    @include margin-padding(0 3px !important, 6px 12px);
}
.fc-state-down, 
.fc-state-active, 
.fc-state-disabled {
    background-color: $__primarycolor !important;
    color: $__white !important;
    text-shadow: $__none !important;
}
.fc-toolbar {
    h2 {
        font-size: $__font__size__18;
        font-weight: $__semibold;
        line-height: 30px;
        text-transform: $__uppercase;
    }
}
th.fc-widget-header {
    background: $__cloud__gray;
    font-size: $__font__size__14;
    line-height: 20px;
    text-transform: $__uppercase;
    border-color: $__light__water__white;
    @include margin-padding(null, 10px 0);
}
.fc-event {
    border: $__none;
    cursor: $__move;
    font-size: $__font__size__13;
    text-align: $__center;
    @include rounded(2px);
    @include margin-padding(1px 7px, 5px);
}

//new

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 1em !important;
}

.fc-button {
    background: #f1f1f1 !important;
    border: none;
    color: #797979 !important;
    text-transform: capitalize;
    box-shadow: none !important;
    border-radius: 3px !important;
    margin: 0 3px !important;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc .fc-button-primary {
    border-color: rgb(232 234 236) !important;
}

.fc .fc-col-header-cell-cushion {
    color: #000 !important;
}

.fc .fc-daygrid-day-number {
    color: #000 !important;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
    font-weight: bold !important;
    color: white !important;
}

.fc-daygrid-dot-event .fc-event-title {
    font-weight: normal !important;
    color:white !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: #00c5fb !important;
    color: #fff !important;
    text-shadow: none !important;
}

.fc-toolbar h2 {
    font-size: 18px !important;
}

.fc .fc-button-group>.fc-button {
    text-transform: capitalize !important;
}

.fc .fc-button-group>.fc-button:focus,
.fc .fc-button-group>.fc-button:active,
.fc .fc-button-group>.fc-button.fc-button-active {
    background-color: #ff9b44 !important;
    color: white !important;
    text-transform: capitalize;
}

.fc .fc-button-primary:disabled {
    color: #fff !important;
    background-color: #ff9b44 !important;
    text-transform: capitalize !important;
}

.fc-col-header {
    background-color: #eeeeee !important;
    font-size: 14px !important;
    line-height: 35px !important;
    padding: 10px 0;
    text-transform: uppercase;
}

.fc-theme-standard td,
.fc-theme-standard th {
    border-color: #f3f3f3 !important;
    border: 1px solid var(--fc-border-color, #ddd);
}

.fc-theme-standard .fc-scrollgrid {
    border-color: #f3f3f3 !important;
    border: 1px solid var(--fc-border-color, #ddd);
}

.fc-col-header-cell {
    background-color: #eeeeee !important;
}

.fc .fc-view-harness {
    height: 500px !important;
}

.fc-daygrid-event-dot {
    border: 0px solid #e9eaea !important;
}

.fc-event {
    line-height: 1.3;
}

.modal-header {
    border-bottom: 0px solid #dee2e6 !important;
    padding: 30px 30px 0 !important;
    justify-content: center !important;
}

.modal-content {
    border-radius: 10px !important;
}

.modal-title {
    font-size: 22px !important;
}

.modal-header .close {
    background-color: #FF7437;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    height: 30px;
    line-height: 20px;
    margin: 0 !important;
    opacity: 1;
    padding: 0 !important;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    z-index: 99;
    border-color: #FF7437;
    border-style:none;
}

.modal-footer {
    border: 0 !important;
    justify-content: center !important;
    padding: 0 30px 30px !important;
}

.modal-body {
    padding: 30px !important;
}

